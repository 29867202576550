@import "abstracts/mixins";

.btnDefault {
    padding: 0.4rem 1rem;
    border-radius: 0.375rem;
    border: 1px solid transparent;
    margin: 0 2px;
    cursor: pointer;

    @include font-poppins(14px, 500, normal, normal, normal, 1.57, #3f4254);
}

// background-color: #E1F0FF;
// border-color: transparent;
// color: #3699FF;
.km-btn {
    @extend .btnDefault ;
    background-color: #0d6efd;
    border-color: #0d6efd;
    color: #ffffff;

    &:hover {
        background: #3699FF;
        color: white;
    }
}

.km-btn-danger {
    @extend .btnDefault ;
    background: #ff4d4f;
    color: #ffffff;
    &:hover {
        background: #ff4d4fed;
    }
}

.btn-secondary {
    @extend .btnDefault ;
    background: #e4e6ef;
    color: #797979;
    border-color: transparent;
}

.km-btn-gallery {
    @extend .btnDefault ;
    padding: 8px;
    height: auto;
    box-shadow: none;
    font-weight: 600;
    @include icont-text-button(#797979);

    &:hover {
        @include icont-text-button(#3699FF);
    }
    &:active {
        @include icont-text-button(#096dd9);
    }
    &:focus{
        @include icont-text-button(#797979);
    }

}

// font-size: 12px;
// font-family: Poppins;
// font-weight: normal;
// font-stretch: normal;
// font-style: normal;
// line-height: 1.57;
// letter-spacing: normal;
