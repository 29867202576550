$white-2:        #f3f0f0;
$black:          #333333;
$gray-1:         #333333;
$gray-2:         #9faab7;
$gray-3:         #e5edef;
$gray-4:         #4e5559;
$gray-5:         #c7c7c7;
$gray-6:         #f7f7f7;
$gray-7:         #eef1f5;
$red-1:          #f75d63;
$red:            #f75d63;
$blue-1:         #2f3b67;
$blue-2:         #505d6c;
$blue-3:         #1a2036;
$yellow:         #fcdf77;
$yellow-2:       #f08f00;
$primary-purple: #212746;
$purple-2:       #6186ff;
$green-2:       #52c41a;

// new
$white:          #ffffff;
$red:            #ff4d4f;
$green:          #50cd89;
$gray:           #7d7d7d;
$gray-easy:      #16192c9e;
$blue:           #3699FF;
