@mixin font-poppins($font-size, $font-weight, $font-stretch, $font-style, $line-height, $letter-spacing, $color, $font: 'regular'){
  @if $font == 'bold' {
    font-family: 'Poppins-SemiBold', sans-serif;
  } @else {
    font-family: 'Poppins-Regular', sans-serif;
  }
  font-size: $font-size;
  font-weight: $font-weight;
  font-stretch: $font-stretch;
  font-style: $font-style;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  color: $color;
  text-shadow: none;
}

@mixin icont-text-button($color){
  transition-property: none;
  border-color: transparent;
  background-color: transparent;
  color: $color;
  i{
      color: $color;
      font-size: 20px;
  }
}