@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");
@import "abstracts/mixins";
@import "abstracts/colors";
@import "components/buttons";


body {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}

.h4-master-guide {
  font-size: 8px;
  font-weight: bold;
}

.title {
  @include font-poppins(
    40px,
    600,
    normal,
    normal,
    1.15,
    -1.88px,
    #332927,
    "bold"
  );
}

.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.ant-btn-primary {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  padding: 0 50px;
  border-radius: 4px;
}

.ant-layout {
  height: initial;
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  // background: #f0f2f5a3;
}

.ant-badge-count,
.ant-badge-dot {
  z-index: auto;
  background-color: #0d6efd !important;
  box-shadow: 0 0 0 1px #fff;
}

//.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//  border-radius: 50px;
//}

//.ant-input-number {
//  width: 170px;
//  border-radius: 10px;
//}

.ant-input-affix-wrapper {
  position: relative;
  width: 100%;
  min-width: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-flex;

  padding: 7px 11px;
  border-radius: 5px;
}

.ant-select-single {
  margin-right: 5px;

  .ant-select-selector {
    .ant-select-selection-placeholder {
      padding: 3px 18px 3px 5px;
    }
  }
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding: 3px 18px 3px 5px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 10px 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  vertical-align: top;
}

.no-float {
  display: block;
  margin: 0 auto;
}

.display-center {
  display: flex;
  justify-content: center;
}

.custom-select {
  width: 100% !important;
}

nz-transfer {
  .ant-transfer-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
}

.pointer {
  cursor: pointer;
}

.no-drop {
  cursor: no-drop;
}

.anticon {
  color: #0d6efd;
}

.container-filter {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  .filter {
    .ant-input-affix-wrapper {
      border: 1px solid #d9d9d99c;

      input {
        width: 241px;
      }
    }
  }
}

.ant-layout-content {
  display: flex;
}

// Setter new

.icon-user {
  display: flex;
  justify-content: center;
  font-size: 60px;
  color: #8c8c8c;
  margin-bottom: 15px;
}

.auth-input {
  padding: 13px;
  border-radius: 8px !important;
}

.km-item-menu-header {
  padding: 10px 30px;

  i {
    padding-right: 10px;
  }
}

//NEW SETTING

.flex {
  display: flex;
}

// btn

.ant-dropdown-trigger {
  border: 0;
  border-radius: 4px;
  color: #4e4f4f;
  cursor: pointer;
  background: #f5f5f5;
  padding: 5px 15px;
}

.ant-dropdown {
  top: -4px;
  left: 0;
  position: relative;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;

  .ant-dropdown-menu {
    position: relative;
    margin: 0;
    padding: 0px 0;
    text-align: left;
    list-style-type: none;
    background-color: #f9f9f9;
    background-clip: padding-box;
    outline: 0;
    border-radius: 0 0 10px 10px;

    .ant-dropdown-menu-item {
      clear: both;
      margin: 0;
      padding: 5px 90px 5px 15px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.3s;

      a {
        color: #4e4f4f;
        .icon-menu-item {
          position: absolute;
          right: 7px;
        }
      }
    }
  }
}

.container-table {
  background: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 1290px;
  margin: 0 auto;
  font-family: "poppins";
  overflow-x: auto;
  .header-table {
    //justify-content: space-between;
    //display: flex;
    //min-height: 18px;
  }

  .icon-default {
    font-size: 20px;
  }

  .active {
    color: #0cc665;
  }

  .danger {
    color: #ff4d4f;
  }
}

.container-table-menu {
  background: #f2f5fa;
  padding: 25px 50px;
  border-radius: 10px;
  margin: 0 auto;
  min-height: 50vh;
}

.observation {
  height: auto !important
}

.ant-input-group .ant-input {
  border-radius: 4px;
  height: 39px;
  background-color: #f5f8fa !important;
  color: #5e6278;
  transition: color 0.2s ease, background-color 0.2s ease;
  border: 1px solid rgb(0 0 0 / 2%);
  padding: 21px 10px;
}
.ant-drawer-open {
  .ant-drawer-mask {
    background-color: rgb(0 0 0 / 72%) !important;
  }
}

// HERE
.ant-drawer {
  .ant-drawer-content {
    // border-radius: 10px 0 0 10px;
    -webkit-box-shadow: -12px 8px 5px -1px rgb(0 0 0 / 9%);
    -moz-box-shadow: -12px 8px 5px -1px rgba(0, 0, 0, 0.09);
    box-shadow: -12px 8px 5px -1px rgb(0 0 0 / 9%);
    /* Estilos para la barra de desplazamiento en Y */
    ::-webkit-scrollbar {
      width: 5px; /* Ancho de la barra de desplazamiento */
    }

    ::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* Color del fondo de la barra de desplazamiento */
    }

    ::-webkit-scrollbar-thumb {
      background-color: #55555530; /* Color de la barra de desplazamiento */
      border-radius: 6px; /* Radio de borde de la barra de desplazamiento */
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #55555530; /* Cambio de color al pasar el ratón sobre la barra */
    }

    .ant-drawer-close {
      display: none;
    }

    //.ant-input-group .ant-input {
    //  border-radius: 8px;
    //  height: 40px;
    //  border: 0;
    //  background-color: #f5f8fa;
    //  border-color: #f5f8fa;
    //  color: #5e6278;
    //  transition: color 0.2s ease, background-color 0.2s ease;
    //}

    textarea {
      height: 100px !important;
    }

    nz-date-picker {
      border-radius: 8px;
      height: 40px;
      border: 0;
      background-color: #f5f8fa;
      border-color: #f5f8fa;
      color: #5e6278;
      transition: color 0.2s ease, background-color 0.2s ease;
    }

    nz-time-picker {
      border-radius: 8px;
      height: 40px;
      border: 0;
      background-color: #f5f8fa;
      border-color: #f5f8fa;
      color: #5e6278;
      transition: color 0.2s ease, background-color 0.2s ease;
    }

    .ant-form-item .ant-select {
      width: 100%;
      text-transform: capitalize;
    }

    .select-concat {
      .ant-select-selector {
        height: 47px !important;
        text-align: center;
        align-items: center;
      }
    }
    .ant-select-item-option {
      display: flex;
      text-transform: capitalize;
      border-radius: 8px;
    }

    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border-radius: 8px;
      height: 40px;
      border: 0;
      background-color: #f5f8fa;
      border-color: #f5f8fa;
      color: #5e6278;
      transition: color 0.2s ease, background-color 0.2s ease;
    }

    .ant-select-multiple .ant-select-selection-item {
      border-radius: 8px;
      background-color: #e7e7e7;
      padding-left: 2px;
    }

    .ant-card-head,
    .ant-card-head .ant-tabs-bar {
      border-bottom: 0px;
    }
  }
}

.ant-modal-content{

  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border-radius: 8px;
      height: 40px;
      border: 0;
      background-color: #f5f8fa;
      border-color: #f5f8fa;
      color: #5e6278;
      transition: color 0.2s ease, background-color 0.2s ease;
    }

  .ant-select-multiple .ant-select-selection-item {
    border-radius: 8px;
    background-color: #e7e7e7;
    padding-left: 2px;
  }
}

//.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//  position: relative;
//  border-radius: 8px;
//  width: 100%;
//  height: 40px;
//  padding: 0px 11px;
//  border: 0;
//  background-color: #f5f8fa;
//  border-color: #f5f8fa;
//  color: #5e6278;
//  transition: color 0.2s ease, background-color 0.2s ease;
//}

.ant-dropdown-trigger {
  border: 0;
  border-radius: 4px;
  color: rgb(0 0 0 / 50%);
  cursor: pointer;
}

.text-capitalize {
  text-transform: capitalize !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}

// TODO mover
.ant-modal-content {
  border-radius: 10px;

  .ant-modal-header {
    border-radius: 10px 10px 0px 0px;
  }

  .ant-input-group .ant-input {
    border-radius: 8px;
    height: 40px;
    border: 0;
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color 0.2s ease, background-color 0.2s ease;

    &:hover,
    &:focus {
      z-index: 0;
    }
  }
}

.ant-input-number {
  width: 100% !important;

  .ant-input-number-input {
    border-radius: 8px;
    height: 40px;
    border: 0;
    width: 100%;
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color 0.2s ease, background-color 0.2s ease;
  }

  .ant-input-number-handler-wrap {
    background-color: transparent;
    border: 0px;
    //border-left: 1px solid rgb(231, 231, 231);
    border-radius: 0px;
  }

  .ant-input-number-handler-down {
    border-top: 0px;
  }
}

.ant-input-group-addon,
.ant-input-number {
  border: 0px;
}

.ant-select-dropdown {
  border-radius: 8px;
}

.ant-spin-dot-item {
  background-color: rgb(0 0 0 / 85%);
}

nz-filter-trigger {
  cursor: pointer;

  .btn-filtre {
    right: 0;
    border-color: transparent;
    color: #ffffff;
    margin-bottom: 10px;

    i {
      color: #ffffff;
    }

    &:hover,
    &:focus-visible {
      background: #3699ff;
    }
  }

  span {
    width: 0 !important;
  }

  &:hover,
  &:focus-visible {
    background: transparent !important;
    outline: none !important;
  }
}

.ant-filtre {
  padding: 10px;
  width: 300px;
  display: flex;
  flex-direction: column;
}

.input-default {
  position: relative;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  padding: 0px 11px;
  border: 0;
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  color: #5e6278;
  transition: color 0.2s ease, background-color 0.2s ease;
}

nz-descriptions {
  text-transform: capitalize;

  td {
    font-weight: bold;
  }
}

.card-info {
  border-radius: 6px;

  i {
    font-size: 14px;
    // margin: 0 5px;
    color: $blue;
  }

  span {
    font-size: 15px;
  }
}

.luuk {
  width: 100% !important;
  padding: 40px;

  .ant-drawer-content {
    border-radius: 8px;
  }
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  border-radius: 10px;
}

.ant-menu-inline .ant-menu-item::after,
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after {
  border: 0px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #3699ff;
  background-color: #e1f0ff;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: 0px;
}

.ant-table-cell {
  &:before {
    background-color: transparent !important;
  }
}

.img-preview {
  height: 235px;
  width: 235px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  transition: height 1.8s;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.05);
  &:hover {
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.07);
    height: 246px;
    transition: height 1.8s;
  }
}

.preview-pff {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: #000000b0;
  padding: 35px 0;

  .close-icon {
    z-index: 1000;
    position: absolute;
    right: 26px;
    font-size: 31px;
    color: #f5f8fa;
    top: 10px;
    cursor: pointer;
  }

  iframe {
    width: 100%;
    height: 96vh;
    margin: 0 auto;
    padding: 22px;
    border: 0;
  }
}

.ant-collapse > .ant-collapse-item {
  border: 0px;
}

.ant-collapse {
  border: 0px;
  background-color: #f5f8fa;
  border-radius: 10px;
}

.ant-collapse-content {
  border: 0px;
}

.container-surcharges {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 50vh;
}

.filter_tigger {
  position: absolute;
  right: 10px;
  top: 20px;
}

.ant-picker {
  border-radius: 8px;
  width: 100%;
  height: 40px;
  border: 0;
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  color: #5e6278;
  padding: 22px 10px;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
  height: 44px;
  background-color: #f5f8fa !important;
  color: #5e6278;
  transition: color 0.2s ease, background-color 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.02);
}

.km-instruccion {
  padding: 30px;

  .ant-col {
    padding-bottom: 5px;
  }
}

.full-field {
  width: 100% !important;
}

.description-card {
  .head-user {
    margin-top: 60px;
  }

  .avatar-drawer {
    margin-bottom: 40px;
  }
}

.description-card {
  .description-card-head {
    margin: 0 10px 30px;
  }
  .description-card-body {
    .text-description-card {
      color: #000000;
      span {
        color: #959595;
      }
    }
  }
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  text-transform: capitalize;
}

.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
}

.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
  margin: 0 15px 0 0;
}

.login-form {
  .ant-form-item-control-input {
    margin: 0px;
  }
}

.drawing {
  .ant-drawer .ant-drawer-content {
    // border-radius: 0 17px 17px 0;
    box-shadow: -12px 8px 5px -1px rgb(0 0 0 / 9%);
  }
}

.textarea-default {
  border: 1px solid #f5f8fa;
  border-radius: 5px;
  background-color: #f5f8fa;
}

.ant-btn-group {
  button {
    cursor: pointer;
    border: 1px solid #1890ff;
    background-color: #1890ff;
    color: white;
    border-radius: 2px 0 0 2px;
    padding: 2px 13px;
    font-size: 15px;
    &:nth-child(2) {
      border-radius: 0 2px 2px 0;
      background-color: #40a9ff00;
      color: rgb(0 0 0 / 50%);
      border: 1px solid rgb(0 0 0 / 16%);
    }
  }
}

.ant-form-inline {
  .ant-form-item {
    margin: 10px 0;
  }
}

// new system desing

.d-none {
  display: none;
}

// Color
.c-white {
  color: $white;
}
.c-red {
  color: $red;
}
.c-green {
  color: $green;
}
.c-gray {
  color: $gray;
}
.c-gray-easy {
  color: $gray-easy;
}
.c-blue {
  color: $blue;
}

.c-green-2 {
  color: $green-2;
}

// Background Color
.b-white {
  background-color: $white;
}
.b-red {
  background-color: $red;
}
.b-green {
  background-color: $green;
}
.b-gray {
  background-color: $gray;
}
.b-blue {
  background-color: $blue;
}
.b-transparent {
  background-color: transparent;
}

.text-bold {
  font-weight: bold;
}

.margin-none {
  margin: 0;
}
.margin-auto {
  margin: auto;
}
.margin-t0 {
  margin-top: 0px;
}
.margin-5p {
  margin: 5px;
}
.margin-2p {
  margin: 2px;
}
.margin-r5p {
  margin-right: 5px;
}
.margin-r10p {
  margin-right: 10px;
}
.margin-l5p {
  margin-left: 5px;
}
.margin-20p {
  margin: 0 20px;
}
.margin-item {
  margin: 0 20px 10px 20px;
}
.margin-t5 {
  margin-top: 5px;
}
.margin-t15 {
  margin-top: 15px;
}
.margin-t10 {
  margin-top: 10px;
}
.margin-b15 {
  margin-bottom: 15px;
}

.padding-t0p {
  padding: 0px!important;
}
.padding-5p {
  padding: 5px;
}
.padding-10p {
  padding: 10px;
}
.padding-20p {
  padding: 20px;
}
.padding-30p {
  padding: 30px;
}
.padding-45p {
  padding: 45px;
}
.padding-l30p {
  padding-left: 30px;
}
.padding-r5p {
  padding-left: 5px;
}
.padding-tnone{
  padding-top: 0px!important;
}
.padding-bnone{
  padding-bottom: 0px!important;
}
.padding-lnone{
  padding-left: 0px!important;
}
.padding-rnone{
  padding-right: 0px!important;
}


.fz-5 {
  font-size: 5px;
}
.fz-10 {
  font-size: 10px;
}
.fz-13 {
  font-size: 13px;
}
.fz-17 {
  font-size: 17px;
}
.fz-20 {
  font-size: 20px;
}
.fz-30 {
  font-size: 30px;
}
.fz-25 {
  font-size: 25px;
}
.fz-15 {
  font-size: 15px;
}
.fz-65 {
  font-size: 65px;
}
.d-flex {
  display: flex;
}
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}

.text-underline {
  text-decoration: underline;
}

.total-right {
  right: 0;
}

.total-left {
  left: 0;
}

.total-top {
  top: 0;
}

.total-bottom {
  bottom: 0;
}

.footer-drawer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
}

// margin
.m-5p {
  margin: 5px;
}

.flex-center {
  display: flex;
  justify-content: center;
}

// Typography
.typography-title {
  @include font-poppins(
    30px,
    600,
    normal,
    normal,
    36px,
    normal,
    #16192c,
    "bold"
  );
}

.typography-sub-title {
  @include font-poppins(
    16px,
    600,
    normal,
    normal,
    21px,
    normal,
    #16192c,
    "bold"
  );
}

.typography-description {
  @include font-poppins(14px, 400, normal, normal, 23px, normal, #525f7f);
}

.input-label {
  @include font-poppins(23px, 500, normal, normal, 23px, normal, #16192c);
}

.ant-form-item-label {
  label {
    @include font-poppins(
      14px,
      500,
      normal,
      normal,
      23px,
      normal,
      #16192cc9,
      "bold"
    );
  }
}

.ant-tabs-nav-wrap {
  border-bottom: 1px solid #d9d9d9;

  .ant-tabs-tab-btn {
    font-family: "Poppins-Regular", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-shadow: none !important;
    color: #525f7f;

    &:hover {
      color: #3699ff !important;
    }
    .ant-tabs-tab-active {
      color: #3699ff !important;
    }
  }
}

// wrappers
.wrapper {
  padding: 30px 10px 20px 10px;
  border-radius: 10px;
  max-width: 1500px;
  margin: 0 auto;
  overflow-x: auto;
}

// badge

.badge-default {
  color: #1890ff;
  background-color: #1890ff26;
  padding: 4px 16px;
  border-radius: 5px;
  font-size: 12px;
}

.badge-success {
  color: #50cd89;
  background-color: #e8fff3;
  padding: 4px 16px;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
}

.badge-danger {
  color: #ff4d4f;
  background-color: #ff4d4f40;
  padding: 4px 16px;
  border-radius: 5px;
  font-size: 12px;
  display: inline-flex;

  .bi {
    margin-left: 6px;
  }
}

.badge-caution {
  color: #ffffff;
  background-color: #faad14;
  padding: 4px 16px;
  border-radius: 5px;
  font-size: 12px;
  display: inline-flex;

  .bi {
    margin-left: 6px;
  }
}

.badge-primary {
  color: #323232;
  background-color: #e1f0ff;
  padding: 4px 16px;
  border-radius: 5px;
  font-size: 13px;
  display: inline-flex;

  .bi {
    margin-left: 6px;
  }
}

.badge-secondary {
  color: #797979;
  background-color: #e4e6ef;
  padding: 4px 16px;
  border-radius: 5px;
  font-size: 11px;
}

// table

// TODO REFACTOR
table {
  margin-top: 30px;

  thead {
    background-color: #f2f2f2;
  }
  .ant-table-thead > tr > th {
    font-weight: 500;
    text-align: left;
    background: #fafafa9c;
    transition: background 0.3s ease;
    margin: 10px 0 2px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: #332927;

    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: $blue;

    font-weight: 500;
    text-align: left;
    background: transparent;
    transition: background 0.3s ease;
    margin: 10px 0 2px;
    /* font-family: Poppins; */
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: #332927;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    /* color: rgb(0 0 0 / 85%); */
    text-transform: capitalize;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #7e8299;

    .table-sub-title {
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      color: #3f4254;
      text-transform: capitalize;
    }
  }

  .divide {
    margin: 0 15px;
  }
}

.ant-list-bordered {
  border: 1px solid #ffffff;
  border-radius: 9px;
  background-color: white;
  cursor: pointer;
}

.ant-image-preview-operations {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum", "tnum";
  position: absolute;
  top: initial;
  right: inherit;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  color: rgb(255 255 255 / 85%);
  list-style: none;
  background: rgb(0 0 0 / 19%);
  pointer-events: auto;
  .anticon {
    color: #ffffff;
  }
}

.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 13px 24px;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #fff;
  border-radius: 11px;

  .ant-notification-close-icon {
    font-size: 14px;
    cursor: pointer;
  }
}

.error-input {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  border: 1px solid #ff4d4f !important;
  background-color: #ff4d4f0f !important;
  border-radius: 8px;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.search-input {
  position: absolute;
  right: 25px;
  top: 12px;
  z-index: 1;
}

.ant-message {
  top: 69px;
  padding-right: 11px;
  .ant-message-notice-content {
    padding: 13px 60px 12px 20px;
    border-radius: 8px;
  }
}
.ant-message-notice {
  padding: 7px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: end;
}

.ant-select-item-option-content {
  text-transform: capitalize;
}

nz-transfer {
  .ant-transfer-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    border: 1px solid rgb(0 0 0 / 5%);
    border-radius: 11px;
    padding: 15px;
  }

  .ant-btn-sm {
    width: 35px;
    height: 24px;
    padding: 0;
    font-size: 14px;
    border-radius: 3px !important;
    color: white;
    border: 1px solid #dedede !important;
    background-color: rgb(0 0 0 / 0%) !important;

    i {
      color: rgb(0 0 0 / 85%);
    }
    &:hover {
      border: 1px solid #dedede !important;
      background-color: rgb(0 0 0 / 0%) !important;
    }
  }
}

.content-none {
  label:after {
    content: "";
  }
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

video {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.trash-image {
  background: rgb(0 0 0 / 63%);
  width: 93%;
  position: absolute;
  bottom: 10px;
  right: 0;
  text-align: center;
  padding: 10px;
  color: #ffffff;
}

.image-camera {
  height: 200px;
  width: 100%;
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
}

.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 13px 24px;

  &:hover {
    -webkit-box-shadow: 1px 12px 12px -6px #0000001f;
    -moz-box-shadow: 1px 12px 12px -6px #0000001f;
    box-shadow: 1px 12px 12px -6px #0000001f;
  }
}

.text-lowercase {
  text-transform: lowercase;
}

.list-file {
  .ant-list-bordered {
    border: 0;
    border-radius: 9px;
    background-color: transparent;
    cursor: pointer;

    .ant-list-lg {
    }
  }
  .ant-list-item {
    padding: 13px 24px;
    border: 1px solid #ffffff;
    border-radius: 17px;
    margin: 10px;
    background-color: #ffffff;
  }
}

.align-center {
  display: flex;
  align-items: center;
}

.btn-upload {
  padding: 15px;
  border: 1px dashed #0000007d;
  background-color: #ffffff;
  color: #0000009e;
  display: flex;
  align-items: center;

  span {
    color: #0000006b;
    font-size: 11px;
  }

  &:hover {
    background: #f6f6f6;
    color: #0000009e;
    border-color: #b6b6b6;
  }
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  text-transform: capitalize;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
  left: auto;
  top: auto;
}


.container-chart {

  .chart-background{
    width: 100%;
    background: white;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
  }

  .container-chart-form {
    padding: 16px !important;
    width: 100%;

    @media (max-width: 800px) {
      margin-top: 30px;
    }

}

  .container-chart-item {
      margin: 0px !important;
      padding-bottom: 16px;
  }
}

.modal-upload-file{
  .ant-modal-content .ant-modal-body{
    padding-top: 0px!important;
  }
}

.app-header{
  .ant-badge-count, .ant-badge-dot{
    background-color: #ff4d4f!important;
  }

}

.notification-container{
  .ant-menu-title-content{
    width: 100% !important;
  }
  .ant-dropdown-menu-submenu-title:hover{
    background-color: transparent!important;
  }
}

.hidden{
  display: none;
}

.km-tr-edit{
  background-color: #e1f0ff;
  &.ant-table-row>td, .ant-table-tbody>tr{
    padding-top: 0px!important;
    padding-bottom: 0px!important;
  }
  &.ant-table-row:hover>td, .ant-table-tbody>tr>td.ant-table-cell-row-hover{
    background-color: #e1f0ff!important;
  }
}

.cdk-global-scrollblock{
  height: auto;
}

.overflow-hidden{
  overflow: hidden !important;
}

.full-loading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fffffff7;
  z-index: 12;
}
.center-roller {
  margin: 15% auto;
  display: block;
}
.lds-roller {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #16192c;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
